import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  reportContainer: {
    margin: '10px',
  },
  reportButtons: {
    display: 'flex',
  },
  reportButton: {
    margin: '10px',
  },
  paper: {
    padding: theme.spacing(2),
  },
}));