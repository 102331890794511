import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  createUser: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  userList: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.primary,
  },
  userInput: {
    width: '100%', // Fix IE 11 issue.
    margin: '10px',
  },
}));