import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Paper, Typography, Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, FormControlLabel, FormLabel, RadioGroup, Radio, TableContainer, TableRow, TableCell, Table, TableHead, TableBody } from '@material-ui/core';
import useStyles from './styles';
import Input from '../Auth/Input';
import { signup, changePassword } from '../../actions/auth';
import { getUsers, deleteUser, updateAccessLevel } from '../../actions/users';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import User from './User/User';

const initialState = { name: '', email: '', password: '', confirmPassword: '', accessLevel: 1, password2: '', confirmPassword2: '' };

const Users = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const currentUser = JSON.parse(localStorage.getItem('profile'));
    const [createUserModalOpen, setCreateUserModalOpen] = useState(false);
    const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
    const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
    const [updateUserAccessLevelModalOpen, setUpdateUserAccessLevelModalOpen] = useState(false);
    const [formData, setFormData] = useState(initialState);
    const [passwordFormData, setPasswordFormData] = useState(initialState);
    const [showPassword, setShowPassword] = useState(false);
    const [changePassUserId, setChangePassUserId] = useState(null);
    const [deleteUserId, setDeleteUserId] = useState(null);
    const [updateAccessLevelUserId, setUpdateAccessLevelUserId] = useState(null);
    const [userToUpdateAccessLevel, setUserToUpdateAccessLevel] = useState(initialState);
    const users = useSelector((state) => state.users);
    const history = useHistory();
    const userToDelete = users.find((user) => deleteUserId ? user._id === deleteUserId : initialState );

    useEffect(() => {
        if(changePassUserId) {
            handleChangePasswordOpen();
        }
        if(deleteUserId) {
            handleDeleteUserModalOpen();
        }
        if(updateAccessLevelUserId) {
            setUserToUpdateAccessLevel(users.find((user) => updateAccessLevelUserId ? user._id === updateAccessLevelUserId : initialState ));
            handleUpdateUserAccessLevelModalOpen();
        }
        dispatch(getUsers());
    }, [changePassUserId, deleteUserId, updateAccessLevelUserId, dispatch]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handlePasswordChange = (e) => {
        setPasswordFormData({ ...passwordFormData, [e.target.name]: e.target.value });
    };

    const handleCreateUserOpen = () => {
        setCreateUserModalOpen(true);
    };

    const handleCreateUserClose = () => {
        setCreateUserModalOpen(false);
    };

    const handleChangePasswordOpen = () => {
        setChangePasswordModalOpen(true);
    };

    const handleChangePasswordClose = () => {
        setChangePassUserId(null);
        setChangePasswordModalOpen(false);
    };

    const handleDeleteUserModalOpen = () => {
        setDeleteUserModalOpen(true);
    };

    const handleDeleteUserModalClose = () => {
        setDeleteUserId(null);
        setDeleteUserModalOpen(false);
    };

    const handleUpdateUserAccessLevelModalOpen = () => {
        setUpdateUserAccessLevelModalOpen(true);
    };
    
    const handleUpdateUserAccesslevelModalClose = () => {
        setUpdateAccessLevelUserId(null);
        setUpdateUserAccessLevelModalOpen(false);
    }

    const handleCreate = () => {
        dispatch(signup(formData));
        history.push('/users');
        setCreateUserModalOpen(false);
    };

    const handleChangePassword = () => {
        dispatch(changePassword(changePassUserId, passwordFormData));
        history.push('/users');
        setChangePassUserId(null);
        setChangePasswordModalOpen(false);
    };

    const handleShowPassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    
    const handleDeleteUser = () => {
        dispatch(deleteUser(deleteUserId));
        history.push('/users');
        setDeleteUserId(null);
        setDeleteUserModalOpen(false);   
    };

    const handleUpdateAccessLevel = () => {
        dispatch(updateAccessLevel(userToUpdateAccessLevel));
        setUpdateAccessLevelUserId(null);
        setUpdateUserAccessLevelModalOpen(false);
    };

    const handleAccessLevelChanged = (e) => {
        setUserToUpdateAccessLevel({...userToUpdateAccessLevel, accessLevel: parseInt(e.target.value)});
        dispatch(updateAccessLevel(userToUpdateAccessLevel));
    };

    if(!currentUser?.result?.name) {
        return (
            <Paper className={classes.paper} >
                <Typography variant="h6" align="center">
                    Please sign in to manage user data.
                </Typography>
            </Paper>
        )
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    { currentUser?.result?.accessLevel === 3 ? (
                        <Button variant="contained" color="primary" className={classes.createUser} onClick={handleCreateUserOpen}>Create New User</Button>
                    ) : (
                        <div/>
                    )}
                </Grid>
                <Grid item xs={3}>
                        <Button variant="contained" color="primary" className={classes.createUser} onClick={() => setChangePassUserId(currentUser.result._id)}>Update My Password</Button>
                </Grid>
                { currentUser?.result?.accessLevel === 3 ? (
                        <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>User name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Access Level</TableCell>
                                    <TableCell>Change Password</TableCell>
                                    <TableCell>Delete User</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {users.map((user) => (
                                <User user={user} setChangePassUserId={setChangePassUserId} setDeleteUserId={setDeleteUserId} setUpdateAccessLevelUserId={setUpdateAccessLevelUserId} />
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    ) : (
                        <TableContainer component={Paper}>
                            <Paper>
                                <Typography>You must be an admin to manage other users.</Typography>
                            </Paper>
                        </TableContainer>    
                )}
            </Grid>
            <Dialog open={createUserModalOpen} onClose={handleCreateUserClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Create a user</DialogTitle>
                <DialogContent>
                    <Input name="name" label="Name" handleChange={handleChange} type="text" />
                    <Input name="email" label="Email Address" handleChange={handleChange} type="email" />
                    <Input name="password" label="Password" handleChange={handleChange} type={showPassword ? "text" : "password"} handleShowPassword={handleShowPassword} />
                    <Input name="confirmPassword" label="Confirm Password" handleChange={handleChange} type="password" />
                    <FormControl className={classes.userInput} component="fieldset">
                        <FormLabel component="legend">Access Level</FormLabel>
                        <RadioGroup aria-label="accessLevel" name="accessLevel" value={formData.accessLevel} onChange={handleChange}>
                            <FormControlLabel value="1" control={<Radio />} label="Read Only" />
                            <FormControlLabel value="2" control={<Radio />} label="Read and Write" />
                            <FormControlLabel value="3" control={<Radio />} label="Admin" />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCreateUserClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleCreate} color="primary">
                    Create
                </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={changePasswordModalOpen} onClose={handleChangePasswordClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Update Password</DialogTitle>
                <DialogContent>
                    <Input name="password2" label="New Password" handleChange={handlePasswordChange} type="password" />
                    <Input name="confirmPassword2" label="Confirm New Password" handleChange={handlePasswordChange} type="password" />
                </DialogContent>
                <DialogActions>
                <Button onClick={handleChangePasswordClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleChangePassword} color="primary">
                    Update
                </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={deleteUserModalOpen} onClose={handleDeleteUserModalClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Delete User</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this user?</Typography>
                    <Typography>{userToDelete?.name}</Typography>
                    <Typography>{userToDelete?.email}</Typography>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleDeleteUserModalClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleDeleteUser} color="Secondary">
                    Delete
                </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={updateUserAccessLevelModalOpen} onClose={handleUpdateUserAccesslevelModalClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Update Access Level</DialogTitle>
                <DialogContent>
                    <FormControl className={classes.userInput} component="fieldset">
                        <FormLabel component="legend">Access Level</FormLabel>
                        <RadioGroup aria-label="updateAccessLevel" name="updateAccessLevel" value={userToUpdateAccessLevel.accessLevel.toString()} onChange={handleAccessLevelChanged}>
                            <FormControlLabel value="1" control={<Radio />} label="Read Only" />
                            <FormControlLabel value="2" control={<Radio />} label="Read and Write" />
                            <FormControlLabel value="3" control={<Radio />} label="Admin" />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleUpdateUserAccesslevelModalClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleUpdateAccessLevel} color="primary">
                    Update
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default Users;
