import { FETCH_ALL, CREATE, UPDATE, DELETE } from "../constants/actionTypes";

const Actions = (plots = [], action) => {
    switch(action.type) {
        case FETCH_ALL:
            return action.payload;
        case CREATE:
            return [...plots, action.payload];
        case UPDATE:
            return plots.map((plot) => plot._id === action.payload._id ? action.payload : plot);
        case DELETE:
            return plots.filter((plot) => plot._id !== action.payload);
        default:
            return plots;
    }
}

export default Actions;