import React, { useEffect, useState } from 'react';
import { Card, CardActions, CardContent, CardMedia, Button, Typography, Divider, Dialog, DialogTitle, DialogContent, Paper, DialogActions } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import useStyles from './styles';
import { useDispatch } from 'react-redux';
import defaultPhoto from '../../../images/default_profile.jpg';
import noImageAvailable from '../../../images/no-img-avail.png'

import { deletePlot } from '../../../actions/plots';

const Plot = ({ plot, currentId, setCurrentId, handleOpenDialog }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem('profile'));
    const [showHeadstoneDialog, setShowHeadstoneDialog] = useState(false);

    useEffect(() => {
        if(currentId) {
            handleOpenDialog();
        }
    }, [currentId]);

    const handleShowHeadstoneDialog = () => {
        setShowHeadstoneDialog(true);
    };

    const handleCloseHeadstoneDialog = () => {
        setShowHeadstoneDialog(false);
    };

    return (
        <div>
            <Card className={classes.card}>
                <CardMedia className={classes.media} component="img" image={plot.photoOne ? (plot.photoOne) : (defaultPhoto)} title={`${plot.firstName} ${plot.lastName}`} />
                <div className={classes.overlay} >
                    <Typography variant="h4">{plot.firstName} {plot.middleName} {plot.lastName} {plot.suffix}</Typography>
                    <Button size="small" variant="contained" color="primary" className={classes.headstoneButton} onClick={handleShowHeadstoneDialog}>View Headstone</Button>
                    { plot.veteran ? (
                            <div>
                                <Divider />
                                <Typography variant="h5">Veteran</Typography>
                                <Divider />
                            </div>
                        ) : (
                            <Divider />
                    )}
                    <Typography variant="body2">{ plot.title && (`Title: ${plot.title}`)}</Typography>
                    <Typography variant="body2">{ plot.maidenName && (`Maiden Name: ${plot.maidenName}`)}</Typography>
                    <Typography variant="body2">{`Sector: ${plot.sector}`}</Typography>
                    <Typography variant="body2">{`Section: ${plot.section}`}</Typography>
                    <Typography variant="body2">{`Row: ${plot.row}`}</Typography>
                    <Typography variant="body2">{`Plot: ${plot.plot}`}</Typography>
                </div>
                <div className={classes.details}>
                    <Typography variant="body2">{`Date of Birth: ${plot.yearOfBirth}-${plot.monthOfBirth}-${plot.dayOfBirth}`}</Typography>
                    <Typography variant="body2">{`Date of Death: ${plot.yearOfDeath}-${plot.monthOfDeath}-${plot.dayOfDeath}`}</Typography>
                    <Typography className={classes.dateHint}>(YYYY-MM-DD)</Typography>
                </div>
                <CardContent>
                    <Typography className={classes.title} variant="h5">{`${plot.inscription}`}</Typography>
                </CardContent>
                <CardContent>
                    <Typography className={classes.details} variant="body2">{`Notes: ${plot.note}`}</Typography>
                </CardContent>
                { user?.result?.accessLevel > 1 ? (
                    <CardActions className={classes.cardActions}>
                        <Button size="small" color="primary" onClick={() => setCurrentId(plot._id)}>
                            <EditIcon fontSize="small" />
                            Edit
                        </Button>
                        <Button size="small" color="primary" onClick={() => dispatch(deletePlot(plot._id))}>
                            <DeleteIcon fontSize="small" />
                            Delete
                        </Button>
                    </CardActions>
                ) : (
                    <div/>
                )}
            </Card>
            <Dialog open={showHeadstoneDialog} onClose={handleCloseHeadstoneDialog}>
                <DialogTitle>Headstone of {plot.firstName} {plot.middleName} {plot.lastName} {plot.suffix}</DialogTitle>
                <DialogContent>
                    <Paper>
                        <img src={plot.photoTwo ? (plot.photoTwo) : (noImageAvailable)}/>
                    </Paper>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseHeadstoneDialog} color="primary" variant="contained">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Plot;