import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import useStyles from './styles';

const SectorSelector = ({ postData, setPostData }) => {

    const classes = useStyles();

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id="sector-label">Sector</InputLabel>
            <Select
                labelId="sector-label"
                variant="outlined"
                value={postData?.sector}
                onChange={(e) => setPostData({ ...postData, sector: e.target.value })}
            >
                <MenuItem value="Cumberland Presbyterian Cemetery North">Cumberland Presbyterian Cemetery North</MenuItem>
                <MenuItem value="Cumberland Presbyterian Cemetery South">Cumberland Presbyterian Cemetery South</MenuItem>
                <MenuItem value="Blessing, Bratton, Chorn Strip">Blessing, Bratton, Chorn Strip</MenuItem>
                <MenuItem value="T.E. Blessing North">T.E. Blessing North</MenuItem>
                <MenuItem value="T.E. Blessing South">T.E. Blessing South</MenuItem>
                <MenuItem value="T.E. Blessing East">T.E. Blessing East</MenuItem>
                <MenuItem value="Garden of Memories Block A">Garden of Memories Block A</MenuItem>
                <MenuItem value="Garden of Memories Block B">Garden of Memories Block B</MenuItem>
                <MenuItem value="Garden of Peace Block C">Garden of Peace Block C</MenuItem>
                <MenuItem value="Garden of Peace Block D North">Garden of Peace Block D North</MenuItem>
                <MenuItem value="Garden of Peace Block D South">Garden of Peace Block D South</MenuItem>
            </Select>
        </FormControl>
    )
}

export default SectorSelector
