import axios from 'axios';

//const API = axios.create({ baseURL: 'http://localhost:5000' })
const API = axios.create({ baseURL: 'https://mca-prd.herokuapp.com/' })

API.interceptors.request.use((req) => {
    if(localStorage.getItem('profile')) {
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    }

    return req;
});

export const fetchPlots = () => API.get('/plots');
export const createPlot = (newPlot) => API.post('/plots', newPlot);
export const updatePlot = (id, updatedPlot) => API.patch(`/plots/${id}`, updatedPlot);
export const deletePlot = (id) => API.delete(`/plots/${id}`);

export const fetchUsers = () => API.get('/users');
export const signIn = (formData) => API.post('/users/signin', formData);
export const signUp = (formData) => API.post('/users/signup', formData);
export const changePassword = (id, formData) => API.patch(`/users/changepassword/${id}`, formData);
export const deleteUser = (id) => API.delete(`/users/${id}`);
export const updateAccessLevel = (updatedUser) => API.patch(`/users/changeaccess/${updatedUser._id}`, updatedUser);
