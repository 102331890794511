import React, { useEffect } from 'react';
import { Container } from '@material-ui/core';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Navbar from './components/Navbar/Navbar';
import Home from './components/Home/Home';
import Auth from './components/Auth/Auth';
import Users from './components/Users/Users';
import Reports from './components/Reports/Reports';

const App = () => {

    useEffect(() => {
        document.title = "MCA Management App"
     }, []);

    return (
        <BrowserRouter>
            <Container maxwidth="lg">
                <Navbar />
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/auth" exact component={Auth} />
                    <Route path="/users" exact component={Users} />
                    <Route path="/reports" exact component={Reports} />
                </Switch>
            </Container>
        </BrowserRouter>
    )
}

export default App;