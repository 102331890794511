import React from 'react'
import { TableRow, TableCell, Button } from '@material-ui/core';

const User = ({ user, setChangePassUserId, setDeleteUserId, setUpdateAccessLevelUserId }) => {

    return (
        <TableRow
            key={user._id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
            <TableCell component="th" scope="row">{user.name}</TableCell>
            <TableCell>{user.email}</TableCell>
            <TableCell>
                {user.accessLevel === 3 && (
                    <>Admin </>
                )}
                {user.accessLevel === 2 && (
                    <>Read/Write </>
                )}
                {user.accessLevel === 1 && (
                    <>Read Only </>
                )}
                <Button variant="contained" color="primary" onClick={() => setUpdateAccessLevelUserId(user._id)}>Change</Button>
            </TableCell>
            <TableCell><Button variant="contained" color="primary" onClick={() => setChangePassUserId(user._id)}>Change Password</Button></TableCell>
            <TableCell><Button variant="contained" color="secondary" onClick={() => setDeleteUserId(user._id)}>Delete User</Button></TableCell>
        </TableRow>
    )
}

export default User
