export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const DELETE_USER = 'DELETE_USER';
export const FETCH_ALL = 'FETCH_ALL';
export const FETCH_ALL_USERS = 'FETCH_ALL_USERS';
export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';
export const SIGNUP = 'SIGNUP';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const UPDATE_ACCESS_LEVEL = 'UPDATE_ACCESS_LEVEL';