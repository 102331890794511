import * as api from '../api';
import { FETCH_ALL_USERS, DELETE_USER, UPDATE_ACCESS_LEVEL } from '../constants/actionTypes';

// Action Creators
export const getUsers = () => async (dispatch) => {
    try {
        const { data } = await api.fetchUsers();

        dispatch({ type: FETCH_ALL_USERS, payload: data });
    } catch (error) {
        console.log(error);
    }
}

export const deleteUser = (id) => async (dispatch) => {
    try {
        const { data } = await api.deleteUser(id);

        dispatch({ type: DELETE_USER, payload: id });
    } catch (error) {
        console.log(error);
    }
}

export const updateAccessLevel = (user) => async (dispatch) => {
    try {
        const { data } = await api.updateAccessLevel(user);

        dispatch({ type: UPDATE_ACCESS_LEVEL, payload: data });
    } catch (error) {
        console.log(error);
    }
}