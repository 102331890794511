import React, { useState, useRef, useEffect } from 'react'
import { Grid, Button, Dialog, DialogContent, DialogActions, DialogTitle, Typography, Paper } from '@material-ui/core';
import SectorSelector from '../Common/SectorSelector';
import { useSelector } from 'react-redux';
import ViewReport from './ViewReport/ViewReport';
import useStyles from './styles';
import ReactToPrint from 'react-to-print';
import { useDispatch } from 'react-redux';
import { getPlots } from '../../actions/plots';

const initialState = { sector: '' };

const Reports = () => {
    const [showVeteranReportDialog, setShowVeteranReportDialog] = useState(false);
    const [showSectorReportDialog, setShowSectorReportDialog] = useState(false);
    const [postData, setPostData] = useState(initialState);
    const [showReport, setShowReport] = useState(false);
    const [reportType, setReportType] = useState('');
    const [filteredPlots, setFilteredPlots] = useState([]);
    const plots = useSelector((state) => state.plots);
    const classes = useStyles();
    const componentRef = useRef();
    const dispatch = useDispatch();
    const currentUser = JSON.parse(localStorage.getItem('profile'));

    useEffect(() => {
        dispatch(getPlots());
    }, [dispatch]);

    const handleCloseVeteranReportDialog = () => {
        setShowVeteranReportDialog(false);
    }

    const handleOpenVeteranReportDialog = () => {
        setShowVeteranReportDialog(true);
    }

    const handleCloseSectorReportDialog = () => {
        setShowSectorReportDialog(false);
    }

    const handleOpenSectorReportDialog = () => {
        setShowSectorReportDialog(true);
    }

    const generateVeteranReport = () => {
        const filteredPlots = plots.filter((plot) => {
            if(plot.sector === postData.sector && plot.veteran) {
                return true;
            }
        }).sort((a, b) => {
            if(a.section && a.section === b.section) {
                if(a.row && a.row === b.row) {
                    return a.plot.localeCompare(b.plot);
                }

                return a.row.localeCompare(b.row);
            }

            return a.section.localeCompare(b.section);
        });
        setFilteredPlots(filteredPlots);
        setReportType('Veteran Report');
        setShowVeteranReportDialog(false);
        setShowReport(true);
    }

    const generateSectorReport = () => {
        const filteredPlots = plots.filter((plot) => {
            if(plot.sector === postData.sector) {
                return true;
            }
        }).sort((a, b) => {
            if(a.section && a.section === b.section) {
                if(a.row && a.row === b.row) {
                    return a.plot.localeCompare(b.plot);
                }

                return a.row.localeCompare(b.row);
            }

            return a.section.localeCompare(b.section);
        });
        console.log(filteredPlots);
        setFilteredPlots(filteredPlots);
        setReportType('Plot Report');
        setShowSectorReportDialog(false);
        setShowReport(true);
    }

    const handleClearReport = () => {
        setFilteredPlots([]);
        setReportType('');
        setShowReport(false);
    }

    if(!currentUser?.result?.name) {
        return (
            <Paper className={classes.paper} >
                <Typography variant="h6" align="center">
                    Please sign in to generate reports.
                </Typography>
            </Paper>
        )
    }

    return (
        <div>
            <Grid container>
                <Grid item>
                    <Button className={classes.reportButton} color="primary" variant="contained" onClick={handleOpenVeteranReportDialog}>Generate Veteran Report</Button>
                </Grid>
                <Grid item>
                    <Button className={classes.reportButton} color="primary" variant="contained" onClick={handleOpenSectorReportDialog}>Generate Sector Report</Button>
                </Grid>
                {showReport ? (
                    <Grid item>
                        <Button className={classes.reportButton} color="secondary" variant="contained" onClick={handleClearReport}>Clear Report</Button>
                    </Grid>
                ) : (
                    <div/>
                )}
            </Grid>
            <Dialog open={showVeteranReportDialog} onClose={handleCloseVeteranReportDialog} aria-labelledby="form-dialog-title" maxWidth="lg">
                <DialogTitle id="form-dialog-title">Veteran Report</DialogTitle>
                <DialogContent>
                    <Typography>Select a Sector to generate the Veteran report.</Typography>
                    <SectorSelector postData={postData} setPostData={setPostData} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseVeteranReportDialog} color="secondary" variant="contained">
                        Cancel
                    </Button>
                    <Button onClick={generateVeteranReport} color="primary" variant="contained">
                        Generate Report
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={showSectorReportDialog} onClose={handleCloseSectorReportDialog} aria-labelledby="form-dialog-title" maxWidth="lg">
                <DialogTitle id="form-dialog-title">Sector Report</DialogTitle>
                <DialogContent>
                    <Typography>Select a Sector to generate the report for all entered plots.</Typography>
                    <SectorSelector postData={postData} setPostData={setPostData} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSectorReportDialog} color="secondary" variant="contained">
                        Cancel
                    </Button>
                    <Button onClick={generateSectorReport} color="primary" variant="contained">
                        Generate Report
                    </Button>
                </DialogActions>
            </Dialog>
            {showReport ? (
                <div>
                <ReactToPrint
                    trigger={() => <Button className={classes.reportButton} color="primary" variant="contained">Print Report</Button>}
                    content={() => componentRef.current}
                />
                <ViewReport ref={componentRef} className={classes.reportContainer} filteredPlots={filteredPlots} selectedSector={postData.sector} reportType={reportType} />
                </div>
            ) : (
                <div></div>
            )}
        </div>
    )
}

export default Reports
