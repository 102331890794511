import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
  },
  smMargin: {
    margin: theme.spacing(1),
  },
  actionDiv: {
    textAlign: 'center',
  },
  searchPaper : {
    padding: '10px',
    margin: '8px',
  },
  searchPaperRight : {
    padding: '10px',
    margin: '8px',
    justifyContent: 'flex-end',
  },
  searchBox: {
    display: 'flex',
    margin: '10px',
  },
  sortGrid: {
    justifyContent: 'space-between',
  },
  searchButton: {
    margin: '8px',
  },
}));