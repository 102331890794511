import * as api from '../api';
import { FETCH_ALL, CREATE, UPDATE, DELETE } from '../constants/actionTypes';

// Action Creators
export const getPlots = () => async (dispatch) => {
    try {
        const { data } = await api.fetchPlots();

        dispatch({ type: FETCH_ALL, payload: data });
    } catch (error) {
        console.log(error);
    }
}

export const createPlot = (plot) => async (dispatch) => {
    try {
        const { data } = await api.createPlot(plot);

        dispatch({ type: CREATE, payload: data });
    } catch (error) {
        console.log(error);
    }
}

export const updatePlot = (id, plot) => async (dispatch) => {
    try {
        const { data } = await api.updatePlot(id, plot);

        dispatch({ type: UPDATE, payload: data });
    } catch (error) {
        console.log(error);
    }
}

export const deletePlot = (id) => async (dispatch) => {
    try {
        await api.deletePlot(id);

        dispatch({ type: DELETE, payload: id });
    } catch (error) {
        console.log(error);
    }
}