import { FETCH_ALL_USERS, DELETE_USER, SIGNUP, CHANGE_PASSWORD, UPDATE_ACCESS_LEVEL } from "../constants/actionTypes";

const Actions = (users = [], action) => {
    switch(action.type) {
        case SIGNUP:
            return [...users, action.payload];
        case FETCH_ALL_USERS:
            return action.payload;
        case CHANGE_PASSWORD:
            return users.map((user) => user._id === action.payload._id ? action.payload : user);
        case DELETE_USER:
            return users.filter((user) => user._id !== action.payload);
        case UPDATE_ACCESS_LEVEL:
            return users.map((user) => user._id === action.payload._id ? action.payload : user);
        default:
            return users;
    }
}

export default Actions;