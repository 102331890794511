import React, { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AppBar, Button, Toolbar, Typography, Grid } from '@material-ui/core';
import mca from '../../images/mca.png';
import { useDispatch } from 'react-redux';
import decode from 'jwt-decode';

import useStyles from './styles';
import { LOGOUT } from '../../constants/actionTypes';

const Navbar = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));

    const logout = () => {
        dispatch({ type: LOGOUT });

        history.push('/');

        setUser(null);
    };

    const home = () => {
        history.push('/');
    };

    const users = () => {
        history.push('/users');
    };

    const reports = () => {
        history.push('/reports');
    }

    useEffect(() => {
        const token = user?.token;

        if(token) {
            const decodedToken = decode(token);

            if(decodedToken.exp * 1000 < new Date().getTime()) logout();
        }

        setUser(JSON.parse(localStorage.getItem('profile')));
    }, [location]);

    return (
        <AppBar className={classes.appBar} position="static" color="inherit">
            <Grid container>
                <Grid item xs={12} sm={4}>
                    <div className={classes.brandContainer}>
                        <Typography className={classes.heading} variant="h4" align="center">Mansfield Cemetery Association</Typography>
                        <img className={classes.image} src={mca} alt="Mansfield Cemetery Association" height="120" />
                    </div>
                </Grid>   
                <Grid item xs={8} sm={4} className={classes.toolbarStart}>
                    <Toolbar >
                        { user && (
                            <div className={classes.userName}>
                                <Button className={classes.navButton} variant="outlined" onClick={home}>View Plots</Button>
                                <Button className={classes.navButton} variant="outlined" onClick={reports}>Generate Reports</Button>
                                <Button className={classes.navButton} variant="outlined" onClick={users}>Manage Users</Button>
                            </div>
                        )}
                    </Toolbar>
                </Grid>   
                <Grid item xs={8} sm={4} className={classes.toolbar}>
                    <Toolbar >
                        { user ? (
                            <div className={classes.profile}>
                                <Typography className={classes.userName} variant="h6">{user.result.name}</Typography>
                                <Button variant="contained" className={classes.logout} color="secondary" onClick={logout}>Logout</Button>
                            </div>
                        ) : (
                            <Button component={Link} to="/auth" variant="contained" color="primary">Sign in</Button>
                        )}
                    </Toolbar>
                </Grid>   
            </Grid>
        </AppBar>
    )
}

export default Navbar;
