import React from 'react'
import { TableRow, TableCell } from '@material-ui/core';

const ReportPlot = ({ plot }) => {
    return (
        <TableRow
            key={plot._id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
            <TableCell component="th" scope="row">{plot.section}</TableCell>
            <TableCell component="th" scope="row">{plot.row}</TableCell>
            <TableCell component="th" scope="row">{plot.plot}</TableCell>
            <TableCell component="th" scope="row">{`${plot.lastName}, ${plot.firstName} ${plot.middleName}`}</TableCell>
            <TableCell component="th" scope="row">{plot.yearOfBirth}</TableCell>
            <TableCell component="th" scope="row">{plot.yearOfDeath}</TableCell>
        </TableRow>
    )
}

export default ReportPlot
