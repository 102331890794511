import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Divider, TextField, Checkbox, Button, Typography, Paper, FormControlLabel, InputLabel, Select, MenuItem, FormControl, Grid } from '@material-ui/core';
import FileBase from 'react-file-base64';
import { useDispatch, useSelector} from 'react-redux';
import useStyles from './styles';
import { createPlot, updatePlot } from '../../actions/plots';
import SectorSelector from '../Common/SectorSelector';

const initialState = { firstName: '', middleName: '', lastName: '', maidenName: '', title: '', suffix: '', yearOfBirth: '', monthOfBirth: '', dayOfBirth: '', yearOfDeath: '', monthOfDeath: '', dayOfDeath: '', sector: '', section: '', row: '', plot: '', deedNumber: '', purchasers: '', veteran: false, inscription: '', note: '', photoOne: '', photoTwo: '' };

const Form = ({ currentId, setCurrentId, handleCloseModal }) => {
    const plot = useSelector((state) => currentId ? state.plots.find((p) => p._id === currentId) : initialState);
    const [postData, setPostData] = useState(plot);
    const classes = useStyles();
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem('profile'));
    const [showPhotoOnePreview, setShowPhotoOnePreview] = useState(false);
    const [showPhotoTwoPreview, setShowPhotoTwoPreview] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        if(currentId) {
            dispatch(updatePlot(currentId, { ...postData, name: user?.result?.name }));
        } else {
            dispatch(createPlot({ ...postData, creator: user?.result?.name }));
        }

        clear();
    };

    const handlePreviewPhotoOneClick = () => {
        setShowPhotoOnePreview(true);
    };

    const handlePreviewPhotoTwoClick = () => {
        setShowPhotoTwoPreview(true);
    };

    const handleClosePhotoOneDialog = () => {
        setShowPhotoOnePreview(false);
    };

    const handleClosePhotoTwoDialog = () => {
        setShowPhotoTwoPreview(false);
    };

    if(!user?.result?.name) {
        return (
            <Paper className={classes.paper} >
                <Typography variant="h6" align="center">
                    Please sign in to create plot data.
                </Typography>
            </Paper>
        )
    }

    const clear = () => {
        setCurrentId(null);
        setPostData({ firstName: '', middleName: '', lastName: '', maidenName: '', title: '', suffix: '', yearOfBirth: '', monthOfBirth: '', dayOfBirth: '', yearOfDeath: '', monthOfDeath: '', dayOfDeath: '', sector: '', section: '', row: '', plot: '', deedNumber: '', purchasers: '', veteran: false, inscription: '', note: '', photoOne: '', photoTwo: '' });
        if(handleCloseModal) {
            handleCloseModal();
        }
    }

    return (
        <div>
            <Paper className={classes.Paper}>
                <form autoComplete="off" noValidate className={`${classes.root} ${classes.form}`} onSubmit={handleSubmit} >
                    <Typography variant="h6">{ currentId ? 'Editing' : 'Creating' } a plot</Typography>
                    <Grid container direction="row">
                        <Grid item>
                            <FormControl className={classes.formControl}>
                                <InputLabel id='title-select-label'>Title</InputLabel>
                                <Select
                                    labelId='title-select-label'
                                    value={postData?.title}
                                    variant="outlined"
                                    onChange={(e) => setPostData({ ...postData, title: e.target.value })} 
                                >
                                    <MenuItem value="Doctor">Doctor</MenuItem>
                                    <MenuItem value="Miss">Miss</MenuItem>
                                    <MenuItem value="Mister">Mister</MenuItem>
                                    <MenuItem value="Mrs">Mrs</MenuItem>
                                    <MenuItem value="Reverend">Reverend</MenuItem>
                                    <MenuItem value="Pastor">Pastor</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <TextField
                                name="firstName" 
                                variant="outlined" 
                                label="First Name"
                                value={postData?.firstName} 
                                onChange={(e) => setPostData({ ...postData, firstName: e.target.value })} />
                        </Grid>
                        <Grid item>
                            <TextField
                                name="middleName" 
                                variant="outlined" 
                                label="Middle Name"
                                value={postData?.middleName}
                                onChange={(e) => setPostData({ ...postData, middleName: e.target.value })} />
                        </Grid>
                        <Grid item>
                            <TextField
                                name="lastName" 
                                variant="outlined" 
                                label="Last Name"
                                value={postData?.lastName}
                                onChange={(e) => setPostData({ ...postData, lastName: e.target.value })} />
                        </Grid>
                        <Grid item>
                            <TextField
                                name="maidenName" 
                                variant="outlined" 
                                label="Maiden Name"
                                value={postData?.maidenName}
                                onChange={(e) => setPostData({ ...postData, maidenName: e.target.value })} />
                        </Grid>
                        <Grid item>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="suffix-label">Suffix</InputLabel>
                                <Select
                                    labelId="suffix-label"
                                    variant="outlined" 
                                    value={postData?.suffix}
                                    onChange={(e) => setPostData({ ...postData, suffix: e.target.value })}
                                >
                                    <MenuItem value="Senior">Senior</MenuItem>
                                    <MenuItem value="Junior">Junior</MenuItem>
                                    <MenuItem value="II">II</MenuItem>
                                    <MenuItem value="III">III</MenuItem>
                                    <MenuItem value="IV">IV</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Divider variant="fullWidth"/>
                    <Grid container direction="row">
                        <Grid item>
                            <TextField  
                                name="yearOfBirth" 
                                variant="outlined" 
                                label="Year of Birth"
                                value={postData?.yearOfBirth}
                                onChange={(e) => setPostData({ ...postData, yearOfBirth: e.target.value })} />
                        </Grid>
                        <Grid item>
                            <TextField 
                                name="monthOfBirth" 
                                variant="outlined" 
                                label="Month of Birth"
                                value={postData?.monthOfBirth}
                                onChange={(e) => setPostData({ ...postData, monthOfBirth: e.target.value })} />
                        </Grid>
                        <Grid item>
                            <TextField 
                                name="dayOfBirth" 
                                variant="outlined" 
                                label="Day of Birth"
                                value={postData?.dayOfBirth}
                                onChange={(e) => setPostData({ ...postData, dayOfBirth: e.target.value })} />
                        </Grid>
                    </Grid>
                    <Divider/>
                    <Grid container direction="row">
                        <Grid item>
                            <TextField 
                                name="yearOfDeath" 
                                variant="outlined" 
                                label="Year of Death"
                                value={postData?.yearOfDeath}
                                onChange={(e) => setPostData({ ...postData, yearOfDeath: e.target.value })} />
                        </Grid>
                        <Grid item>
                            <TextField 
                                name="monthOfDeath" 
                                variant="outlined" 
                                label="Month of Death"
                                value={postData?.monthOfDeath}
                                onChange={(e) => setPostData({ ...postData, monthOfDeath: e.target.value })} />
                        </Grid>
                        <Grid item>
                            <TextField 
                                name="dayOfDeath" 
                                variant="outlined" 
                                label="Day of Death"
                                value={postData?.dayOfDeath}
                                onChange={(e) => setPostData({ ...postData, dayOfDeath: e.target.value })} />
                        </Grid>
                    </Grid>
                    <Divider/>
                    <Grid container direction="row">
                        <Grid item>
                            <SectorSelector postData={postData} setPostData={setPostData} />
                        </Grid>
                        <Grid item>
                            <TextField 
                                name="section" 
                                variant="outlined" 
                                label="Section"
                                value={postData?.section}
                                onChange={(e) => setPostData({ ...postData, section: e.target.value })} />
                        </Grid>
                        <Grid item>
                            <TextField 
                                name="row" 
                                variant="outlined" 
                                label="Row"
                                value={postData?.row}
                                onChange={(e) => setPostData({ ...postData, row: e.target.value })} />
                        </Grid>
                        <Grid item>
                            <TextField 
                                name="plot" 
                                variant="outlined" 
                                label="Plot"
                                value={postData?.plot}
                                onChange={(e) => setPostData({ ...postData, plot: e.target.value })} />
                        </Grid>
                        <Grid item>
                            <TextField 
                                name="deedNumber" 
                                variant="outlined" 
                                label="Deed Number"
                                value={postData?.deedNumber}
                                onChange={(e) => setPostData({ ...postData, deedNumber: e.target.value })} />
                        </Grid>
                        <Grid item>
                            <TextField 
                                name="purchasers" 
                                variant="outlined" 
                                label="Purchasers"
                                value={postData?.purchasers}
                                onChange={(e) => setPostData({ ...postData, purchasers: e.target.value })} />
                        </Grid>
                    </Grid>
                    <Divider/>
                    <Grid container direction="row">
                        <Grid item>
                            <FormControlLabel
                                className={classes.veteranCheckbox}
                                control={
                                    <Checkbox
                                        name="veteran"
                                        variant="outlined"
                                        checked={postData?.veteran}
                                        onChange={(e) => setPostData({ ...postData, veteran: e.target.checked })} />
                                }
                                label="Veteran"
                                labelPlacement="end" />
                        </Grid>
                        <Grid item>
                            <TextField 
                                name="inscription" 
                                variant="outlined" 
                                label="Inscription"
                                value={postData?.inscription}
                                onChange={(e) => setPostData({ ...postData, inscription: e.target.value })} />
                        </Grid>
                        <Grid item>
                            <TextField 
                                name="note" 
                                variant="outlined" 
                                label="Note"
                                value={postData?.note}
                                onChange={(e) => setPostData({ ...postData, note: e.target.value })} />
                        </Grid>
                        <div className={classes.fileInput}>
                        <InputLabel>Photo 1 (will be shown on plot header)</InputLabel>
                            <FileBase 
                                type="file"
                                multiple={false}
                                onDone={({base64}) => setPostData({ ...postData, photoOne: base64 })}   
                            />
                            {postData?.photoOne ? 
                                (<div>
                                    <Button variant="contained" onClick={handlePreviewPhotoOneClick}>Preview current image</Button>
                                </div>) :
                                (<div/>)
                            }
                        </div>
                        <div className={classes.fileInput}>
                            <InputLabel>Photo 2 (headstone photo)</InputLabel>
                            <FileBase 
                                type="file"
                                multiple={false}
                                onDone={({base64}) => setPostData({ ...postData, photoTwo: base64 })}   
                            />
                            {postData?.photoTwo ? 
                                (<div>
                                    <Button variant="contained" onClick={handlePreviewPhotoTwoClick}>Preview current image</Button>
                                </div>) :
                                (<div/>)
                            }
                        </div>
                    </Grid>
                    <Button className={classes.buttonSubmit} variant="contained" color="primary" size="large" type="submit" fullWidth>Submit</Button>
                    <Button className={classes.buttonSubmit} variant="contained" color="secondary" size="small" onClick={clear} fullWidth>{ currentId ? 'Cancel' : 'CLear' }</Button>
                </form>
            </Paper>
            <Dialog open={showPhotoOnePreview} onClose={handleClosePhotoOneDialog}>
                <DialogTitle>Currently set Photo One</DialogTitle>
                <DialogContent>
                    <Paper>
                        <img src={postData.photoOne}/>
                    </Paper>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosePhotoOneDialog} color="primary" variant="contained">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={showPhotoTwoPreview} onClose={handleClosePhotoTwoDialog}>
                <DialogTitle>Currently set Photo Two</DialogTitle>
                <DialogContent>
                    <Paper>
                        <img src={postData.photoTwo}/>
                    </Paper>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosePhotoTwoDialog} color="primary" variant="contained">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Form;