import React, { useEffect, useState } from 'react'
import { Container, Grow, Grid, Dialog, Button, DialogContent } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import Plots from '../Plots/Plots';
import { getPlots } from '../../actions/plots';
import Form from '../Form/Form';

const Home = () => {
    const [currentId, setCurrentId] = useState(null);
    const [createPlotOpen, setCreatePlotOpen] = useState(false);
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem('profile'));

    useEffect(() => {
        dispatch(getPlots());
    }, [currentId, dispatch]);

    const handleCreatePlotOpen = () => {
        setCreatePlotOpen(true);
    };

    const handleCreatePlotClose = () => {
        setCreatePlotOpen(false);
    };

    return (
        <div>
            <Grow in>
                <Container>
                    <Grid container justifyContent="flex-start" alignItems="stretch">
                        { user?.result?.accessLevel > 1 ? ( 
                            <Grid item xs={3}>
                                <Button color="primary" variant="contained" onClick={handleCreatePlotOpen}>Add a plot</Button>
                            </Grid>
                        ) : (
                            <div/>
                        )}
                        <Grid item xs={12}>
                            <Plots currentId={currentId} setCurrentId={setCurrentId} handleOpenDialog={handleCreatePlotOpen}/>
                        </Grid>
                    </Grid>
                </Container>
            </Grow>
            <Dialog open={createPlotOpen} onClose={handleCreatePlotClose} aria-labelledby="form-dialog-title" maxWidth="lg">
                <DialogContent>
                    <Form currentId={currentId} setCurrentId={setCurrentId} handleCloseModal={handleCreatePlotClose} />
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default Home;
