import React from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody, Typography } from '@material-ui/core';
import ReportPlot from './ReportPlot/ReportPlot';

class ViewReport extends React.PureComponent {
    render() {
        return (
            <TableContainer className={this.props.className} component={Paper}>
                <Typography variant="h4">{`Mansfield Cemetery Association ${this.props.selectedSector} ${this.props.reportType}`}</Typography>
                <Typography variant="h6">{`Total plots in report: ${this.props.filteredPlots.length}`}</Typography>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Section</TableCell>
                            <TableCell>Row</TableCell>
                            <TableCell>Plot</TableCell>
                            <TableCell>Name (Last, First, Middle)</TableCell>
                            <TableCell>Year of Birth</TableCell>
                            <TableCell>Year of Death</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {this.props.filteredPlots?.map((plot) => (
                        <ReportPlot plot={plot} />
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    } 
}

export default ViewReport;