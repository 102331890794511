import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: '10px',
  },
  fileInput: {
    width: '97%',
    margin: '10px 0',
  },
  buttonSubmit: {
    marginBottom: 10,
  },
  veteranCheckbox: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  formControl: {
    margin: '8px',
    minWidth: 120,
  },
  selectLabel: {
    transform: 'translate(14px, 24px) scale(1)',
  },
  mainGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  }
}));