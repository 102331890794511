import React, { useState, useEffect } from 'react';
import { Button, Grid, CircularProgress, TextField, Paper, Typography, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox } from '@material-ui/core';
import { useSelector } from 'react-redux';

import Plot from './Plot/Plot';
import useStyles from './styles';

const Plots = ({ currentId, setCurrentId, handleOpenDialog }) => {
    const plots = useSelector((state) => state.plots);
    const [filteredPlots, setFilteredPlots] = useState([]);
    const [searchTerm, setSearchTerm] = useState(null);
    const [sortField, setSortField] = useState('');
    const [showEmpty, setShowEmpty] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        if(filteredPlots.length < 1) {
            setFilteredPlots(plots);
        }
     }, [plots]);

    const handleSearchTermChange = (e) => {
        if(!e.target.value && !searchTerm) return;
        setSearchTerm(e.target.value);
    };

    const handleSortFieldChange = (e) => {
        setSortField(e.target.value);
    };

    const handleShowEmptyChange = (e) => {
        setShowEmpty(e.target.checked);
    };

    const handleSearchClick = () => {
        setFilteredPlots(plots.filter((plot) => {
            if (!searchTerm) return true
            if (plot.firstName.toLowerCase().includes(searchTerm.toLowerCase()) || 
                plot.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                plot.middleName.toLowerCase().includes(searchTerm.toLowerCase()) || 
                plot.maidenName.toLowerCase().includes(searchTerm.toLowerCase())) {
                return true
            }
        }));
    };

    const handleResetClick = () => {
        setFilteredPlots(plots);
        setSearchTerm(null);
    };

    const handleSearchTermKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearchTermChange(e);
            handleSearchClick();
          }
    };

    return (
        !plots.length ? <CircularProgress /> : (
            <Grid container className={classes.sortGrid}>
                <Grid item xs={8} sm={4}>
                    <Paper className={classes.searchPaper}>
                        <Typography variant="body2">Search by first, last, middle, or maiden name</Typography>
                        <TextField className={classes.searchBox}
                            label="Search for a loved one" 
                            variant="outlined" 
                            onKeyDown={handleSearchTermKeyDown}
                            onBlur={handleSearchTermChange}/>
                        <Button className={classes.searchButton} color="primary" onClick={handleResetClick}>Reset</Button>
                        <Button className={classes.searchButton} color="primary" variant="contained" onClick={handleSearchClick}>Search</Button>
                    </Paper>
                </Grid> 
                <Grid item xs={4}>
                    <Paper className={classes.searchPaperRight}>
                        <Typography variant="body2">Sort by</Typography>
                        <FormControl>
                            <Select
                                variant="outlined"
                                value={sortField}
                                onChange={handleSortFieldChange} 
                            >
                                <MenuItem value="first">First Name</MenuItem>
                                <MenuItem value="last">Last Name</MenuItem>
                                <MenuItem value="maiden">Maiden Name</MenuItem>
                                <MenuItem value="sector">Sector</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="showEmptyCheckbox"
                                    variant="outlined"
                                    checked={showEmpty}
                                    onChange={handleShowEmptyChange} />
                            }
                            label="Show plots with missing info"
                            labelPlacement="start" />
                    </Paper>
                </Grid> 
                <Grid className={classes.mainContainer} container alignItems="stretch" spacing={3}> 
                {filteredPlots
                    .sort((a, b) => {
                        switch (sortField) {
                            case "first":
                                return a.firstName.localeCompare(b.firstName);
                            case "last":
                                return a.lastName.localeCompare(b.lastName);
                            case "maiden":
                                return a.maidenName.localeCompare(b.maidenName);
                            case "sector":
                                return a.sector.localeCompare(b.sector);
                            default:
                                return a.sector.localeCompare(b.sector);
                        }
                    })
                    .filter(plot => {
                        if(!showEmpty) {
                            switch (sortField) {
                                case 'first':
                                    if (plot.firstName.trim() !== "") {
                                        return true;
                                    }
                                    break;
                                case 'last':
                                    if (plot.lastName.trim() !== "") {
                                        return true;
                                    }
                                    break;
                                case 'maiden':
                                    if (plot.maidenName.trim() !== "") {
                                        return true;
                                    }
                                    break;
                                case 'sector':
                                    if (plot.sector.trim() !== "") {
                                        return true;
                                    }
                                    break;
                                default:
                                    return true;
                            }
                        } else {
                            return true;
                        }
                    })
                    .map(plot => (
                        <Grid key={plot._id} item xs={6} sm={3}>
                            <Plot plot={plot} currentId={currentId} setCurrentId={setCurrentId} handleOpenDialog={handleOpenDialog}/>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        )
    );
}

export default Plots;